@font-face {
	font-family: 'Rubik';
	src: local('Rubik'), url(./fonts/Rubik/static/Rubik-Medium.ttf) format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'Rubik';
	src: local('Rubik'), url(./fonts/Rubik/static/Rubik-Regular.ttf) format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: 'Rubik';
	src: local('Rubik'), url(./fonts/Rubik/static/Rubik-Light.ttf) format('truetype');
	font-weight: 200;
}

@font-face {
	font-family: 'Rubik';
	src: local('Rubik'), url(./fonts/Rubik/static/Rubik-SemiBold.ttf) format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: 'Rubik';
	src: local('Rubik'), url(./fonts/Rubik/static/Rubik-Bold.ttf) format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'Rubik';
	src: local('Rubik'), url(./fonts/Rubik/static/Rubik-ExtraBold.ttf) format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: 'Rubik';
	src: local('Rubik'), url(./fonts/Rubik/static/Rubik-Black.ttf) format('truetype');
	font-weight: 900;
}

html {
	font-family: 'rubik', sans-serif;
}


.firebase_signin {
	background: transparent;
}

.firebase_signin div, .firebase_signin div form div, .firebase_signing div form div div {
	background: transparent;
}

.react-pdf__Page__canvas {
	transition: 0.5s;
}

.square_button {
	width: 30px;
	height: 30px;
	background: rgba(255, 255, 255, 0.9);
	border: 1px #afafaf solid;
	margin: 5px;
	border-radius: 3px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.react-pdf__Document {
	position: relative;
	display: flex;
	align-self: center;
	justify-self: center;
	margin: auto;
}


.App {
	text-align: center;
	font-family: 'Rubik', sans-serif;
}

.no_wrap {
	flex-wrap: nowrap;
	white-space: nowrap;
}

h1, h2, h3, .rubik_font {
	font-family: 'Rubik', sans-serif;
}

h4 {
	margin-bottom: 0px;
}

.secondary_text {
	margin-bottom: 10px;
	color: silver;
	font-size: 18px;
}

.fadeOut {
	opacity: 0;
	transition: 0.2s;
}

.fadeIn {
	opacity: 1;
	transition: 0.2s;
}

.sidebar_item {
	height: 50px;
	width: 100%;
	padding-left: 15px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.sidebar_item:hover {
	transition: 0.1s;
	padding-left: 20px;
	background: rgba(0, 0, 0, 0.05);
}

.sidebar_item_active {
	background: rgba(0, 0, 0, 0.15);
}


.usage_container {
	min-height: 150px;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.35);
	margin: 0px;
	margin-top: 0px;
}

.usage_header {
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	color: #333;
	font-weight: 300;
	margin: 0px
}

.email_tab {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 200;
	cursor: pointer;
	transition: 0.2s;
	background: #ddd;
	padding: 10px;
}


.no_antialias {
	image-rendering: pixelated;
	/* image-rendering: -moz-crisp-edges; */
}


.usage_tab_button {
	font-weight: 400;
	font-size: 14pt;
	display: flex;
	justify-content: center;
	cursor: pointer;
	width: 100%;
	height: 100%;
	transition: 0.2s;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 5px;
}

.usage_tab_button:hover {
	background: rgba(0, 0, 0, 0.15);
}

.usage_tab_active {
	background: rgba(0, 0, 0, 0.05);
}

.show_modal {
	bottom: 10px;
	opacity: 1;
	transition: 0.6s;
}

.scrollable_modal {
	position: auto !important;
	overflow: auto !important;
}

.email_tab_selected {
	background: #eee;
}

.text_layer_item_selected {
	border: 1px black solid;
	border-radius: 2px;
}

.text_layer_item {
	display: block;
	transition: 0.5s;
}

.text_layer_item:hover {
	background: silver;
	color: white;
}


.loading_spinner {
	-webkit-animation: rotate 0.35s linear infinite;
	-moz-animation: rotate 0.35s linear infinite;
	animation: rotate 0.35s linear infinite;
}

.iconBox_sq {
	border-radius: 4px;
	box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
	color: #333;
	background: silver;
	padding: 2px;
	font-size: 20px;
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.iconContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: '3px';
}

.custom_navbar {
	z-index: 9;
	position: fixed;
	top: 0px;
	height: 50px;
	width: 100vw;
	padding-left: 10px;
	padding-right: 10px;
}

/* Mobile screens (up to 767px) */
/* @media (max-width: 767px) {
	.custom_navbar {
		z-index: 9;
		position: fixed;
		top: 0px;
		height: 50px;
		width: 100vw;
	}
} */