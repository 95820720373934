.clean_primary {
	background-color: #13bff2;
	box-shadow: 0px 4px 10px rgba(0, 51, 67, 0.25);
}
.clean_primary:hover {
	background-color: #49ccf4;
}  

.clean_primary:active {
	background-color: #0a7596;
}

.clean_primary:focus {
	background-color: #0095c2;
}
.clean_success {
	background-color: #20c997;
	box-shadow: 0px 4px 10px rgba(32, 201, 151, 0.25);
}
.clean_success:hover {
	background-color: #17a88f;
}  

.clean_success:active {
	background-color: #12866e;
}

.clean_secondary {
	background-color: #b2b2b2;
	box-shadow: 0px 4px 10px rgba(56, 56, 56, 0.25);
}
.clean_secondary:hover {
	background-color: #c8c8c8;
}  

.clean_secondary:active {
	background-color: #777777;
}

.clean_danger {
	background-color: #dc3e3e;
	box-shadow: 0px 4px 10px rgba(103, 11, 11, 0.25);
}
.clean_danger:hover {
	background-color: #ff4949;
}  

.clean_danger:active {
	background-color: #a12d2d;
}

.clean_button {
	display: inline-block;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	line-height: 16px;
	min-height: 40px;
	min-width: 100px;
	font-weight: 500;
	color: #ffffff;
	transition: background-color 0.3s ease, transform 0.2s ease, 0.1s ease;
	cursor: pointer;
	overflow: hidden;
}

.clean_button :first-child {
	transition: 0.1s;
}

.clean_button:hover :first-child{
	transform: scale(0.9);
	/* padding: 10px 22px; */
}


.clean_button:active :first-child{
	transform: scale(0.8);
	box-shadow: none;
}
  
.clean_button:focus {
	outline: none;
	box-shadow: none;
}

.btn {
	font-weight: 300;
}

input {
	font-family: 'Rubik';
	font-weight: 200;
}
 
input:focus, input.form-control:focus {
	outline:none !important;
	outline-width: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
  }
  
