canvas {
	position: fixed;
	background-color: #e1fed6;
	z-index: 1;
	width: 100vw;
	height: 100vh;
	top: 50px;
	right: 0px;
	left: 0px;
	max-width: 100vw;
	max-height: 100vh;
	transition: opacity 1s;
}

.landing-banner {
	min-height: 1250px;
	height: 100vh;
	font-family: 'Rubik', sans-serif;
	z-index: 3;
}

.sticky-block-container-1 {
	/* border: 1px red solid; */
	min-height: 1050px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	justify-content: space-between;
	padding: 100px;
}

.sticky-block-container-2 {
	/* border: 1px red solid; */
	min-height: 500px;
	margin-bottom: 700px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	justify-content: space-between;
	max-width: 50%;
}

.sticky-block-container-3 {
	min-height: 900px;
	margin-top: 500px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	justify-content: space-between;
	width: 43%;
	margin-bottom: 300px;
}

.sticky-block-container-4 {
	min-height: 1050px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	justify-content: space-between;
	width: 45%;
	padding-right: 50px;
}

.sticky-block-1 {
	position: -webkit-sticky;
	position: sticky;
	top: 40%;
}

.sticky-block-2 {
	position: -webkit-sticky;
	position: sticky;
	top: 40%;
	z-index: 6;
}

.sticky-block-3 {
	position: -webkit-sticky;
	position: sticky;
	top: 30%;
}

.beta_modal {
	position: fixed;
	bottom: -300px;
	left: 10px;
	margin: 0px;
	margin-bottom: 0px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	transition: 0.6s;
}

.sticky-container-wrapper {
	z-index: 6;
	padding: 100px;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
}

.super_heading {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-self: flex-start;
	z-index: 2;
	margin-top: 25%;
}

.pricing_card {
	/* min-width: 240px; */
	width: 33%;
	/* max-width: 90%; */
	height: 230px;
}

.pricing_container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-content: center;
	flex-wrap: wrap;
}

#pricing {
	flex-direction: column;
	display: flex;
	flex-wrap: wrap;
	padding-top: 50px;
	justify-content: center;
	align-content: center;
	margin: 0px;
	z-index: 5;
	position: relative;
	background: #c5faff;
	min-height: 80vh;
	width: auto;
}

.spinner_container::before {
	width: 100%;
	height: 100%;
	background-image: url('./img/parsley_5.png');
	background-size: 800px 800px;

	-webkit-animation:rotate 300s linear infinite;
	-moz-animation:rotate 300s linear infinite;
	animation:rotate 300s linear infinite;
	transform-origin: center;
}

.parsley_grid_image {
	position: absolute;
	top: -90vw;
	height: 90vw;
	left: 0;
	width: 90vw;
	z-index: -1;
	user-select: none;
	-webkit-user-select: none;
	background-repeat: no-repeat;
}

.spinner_container {
	width: 98vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	left: 0px;
	position: absolute;
}

/* Mobile screens (up to 767px) */
@media only screen and (max-width: 767px) {
	.super_heading {
		margin-bottom: 50vh;
		text-align: center;
		padding: 20px;
	}

	.landing-banner {
		min-height: none;
		height: 100vh;
		font-family: 'Rubik', sans-serif;
		z-index: 3;
		/* overflow: hidden; */
	}

	.landing-banner img {
		width: 800px;
		height: 800px;
		position: absolute;
		/* margin-left: -90%; */
	}

	.sticky-container-wrapper {
		padding: 20px;
		min-width: 100vw;
	}
		
	.sticky-block-container-1 {
		/* border: 1px blue solid; */
		min-height: none;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		justify-content: space-between;
		padding: 0px;
	}
	
	.sticky-block-container-2 {
		/* border: 1px orange solid; */
		margin-top: 300px;
		min-height: 1700px;
		margin-bottom: 200px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		justify-content: space-between;
		max-width: none;
		text-align: center;
		padding: 0px;
	}
	
	.sticky-block-container-3 {
		/* border: 1px red solid; */
		width: auto;
		min-height: 1000px;
		margin: 0px;
		margin-top: 0px;
		margin-bottom: 600px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		justify-content: space-between;
		text-align: center;
		padding: 0px;
	}
	
	.sticky-block-container-4 {
		min-height: 1050px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		justify-content: space-between;
		max-width: none;
		padding: 20px;
	}
	
	.sticky-block-1 {
		position: -webkit-sticky;
		position: sticky;
		top: 50%;
	}
	
	.sticky-block-2 {
		position: -webkit-sticky;
		position: sticky;
		top: 60px;
		z-index: 6;
	}
	
	.sticky-block-3 {
		position: -webkit-sticky;
		position: sticky;
		top: 10%;
	}
	
	.beta_modal {
		position: fixed;
		bottom: -300px;
		left: 2%;
		margin: 0px;
		width: 96%;
		margin-bottom: 0px;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		transition: 0.6s;
	}
	
	.pricing_card {
		/* max-width: 90%; */
		height: 230px;
		margin: 0;
		margin-top: 30px;
		width: 90%;
	}
	
	.pricing_card h3 {
		text-align: center;
	}
	
	.pricing_container {
		display: flex;
		flex-direction: column;
		width: auto;
		align-content: center;
		/* flex-wrap: wrap; */
	}

	#pricing {
		padding: 10px;
		width: auto;
	}

	.parsley_grid_image {
		background-size: 350% 175%;
		background-position: bottom;
		width: 99vw;
		height: 200vw;
		top: -200vw;
	}
}

/* Tablet screens (768px to 1023px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
	/* canvas {
		position: fixed;
		background-color: #e1fed6;
		z-index: 1;
		width: 100vw;
		height: 100vh;
		max-width: 100vw;
		max-height: 100vh;
		transition: opacity 1s;
	} */
	
	.landing-banner {
		min-height: 1250px;
		height: 100vh;
		font-family: 'Rubik', sans-serif;
		z-index: 3;
	}
	
	.sticky-block-container-1 {
		/* border: 1px red solid; */
		min-height: 1050px;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		justify-content: space-between;
		padding: 100px;
	}
	
	.sticky-block-container-2 {
		/* border: 1px red solid; */
		min-height: 500px;
		margin-bottom: 700px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		justify-content: space-between;
		max-width: 50%;
	}
	
	.sticky-block-container-3 {
		min-height: 900px;
		margin-top: 500px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		justify-content: space-between;
		width: 43%;
		margin-bottom: 300px;
	}
	
	.sticky-block-container-4 {
		min-height: 1050px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		justify-content: space-between;
		width: 45%;
		padding-right: 50px;
	}
	
	.sticky-block-1 {
		position: -webkit-sticky;
		position: sticky;
		top: 40%;
	}
	
	.sticky-block-2 {
		position: -webkit-sticky;
		position: sticky;
		top: 40%;
		z-index: 6;
	}
	
	.sticky-block-3 {
		position: -webkit-sticky;
		position: sticky;
		top: 30%;
	}
	
	.beta_modal {
		position: fixed;
		bottom: -300px;
		left: 10px;
		margin: 0px;
		margin-bottom: 0px;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		transition: 0.6s;
	}
}

/* Desktop screens (1024px and up) */
@media (min-width: 1024px) {
}

