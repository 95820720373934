.spinner {
  -webkit-animation:rotate_slow 300s linear infinite;
  -moz-animation:rotate_slow 300s linear infinite;
  animation:rotate_slow 300s linear infinite;
  width: 100vw;
  height: 100vw;
  transform: translate(-50%, -15%);
  transform-origin: center;
}

@-moz-keyframes rotate_slow {
  100% { -moz-transform: translate(-50%, -15%) rotate(360deg); }
}

@-webkit-keyframes rotate_slow {
  100% { -webkit-transform: translate(-50%, -15%) rotate(360deg); }
}

@keyframes rotate_slow {
  100% {
      -webkit-transform: translate(-50%, -15%) rotate(360deg);
      transform: translate(-50%, -15%) rotate(360deg);
  }
}

@-moz-keyframes rotate {
  100% { -moz-transform: translate(0%, 0%) rotate(360deg); }
}

@-webkit-keyframes rotate {
  100% { -webkit-transform: translate(0%, 0%) rotate(360deg); }
}

@keyframes rotate {
  100% {
      -webkit-transform:  translate(0%, 0%) rotate(360deg);
      transform: translate(0%, 0%) rotate(360deg);
  }
}



/* -------------------------------------- */
/* css 3d perspective document with hovering "data" lines */

.animated_data_layer_1 {
	transform: translateZ(20px);
	animation: 10s infinite linear data_layer_1;
}

.animated_data_layer_2 {
	transform: translateZ(30px);
	animation: 10s infinite linear data_layer_2;
	animation-delay: 2s;
}

.animated_data_layer_3 {
	transform: translateZ(5px);
	animation: 8s infinite linear data_layer_3;
	animation-delay: 1s;
}

@keyframes data_layer_1 {
	0% {
	  transform: translateZ(20px);
  }

  50% {
	  transform: translateZ(50px);
  }

  100% {
	  transform: translateZ(20px);
  }
}

@keyframes data_layer_2 {
	0% {
	  transform: translateZ(30px);
  }

  50% {
	  transform: translateZ(50px);
  }

  100% {
	  transform: translateZ(30px);
  }
}

@keyframes data_layer_3 {
	0% {
	  transform: translateZ(5px);
  }

  50% {
	  transform: translateZ(50px);
  }

  100% {
	  transform: translateZ(5px);
  }
}
